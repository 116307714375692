import { getProType } from '@/api/recommend';

export default {
	namespaced: true,
	state() {
		return {
			proType: [],
			basketType: [],
		};
	},
	mutations: {
		setProType(state, { footType, basketType }) {
			// state.proType = value;
			state.proType = footType;
			state.basketType = basketType;
		},
	},
	actions: {
		async fetchProType(context) {
			const [{ data: footType }, { data: basketType }] = await Promise.all([getProType(0), getProType(1)]);
			// const { data } = await getProType();
			context.commit('setProType', { footType, basketType });
		},
	},
};
