import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import '@/style/main.css';
import 'element-ui/lib/theme-chalk/index.css';
// import 'vue-cropperjs'
// import 'lib-flexible';
// 解决使用dialog弹出，页面垂直滚动条消失
ElementUI.Dialog.props.lockScroll.default = false;
// 全局修改默认配置，点击空白处不能关闭弹窗
ElementUI.Dialog.props.closeOnClickModal.default = false;
// 全局修改默认配置，按下ESC不能关闭弹窗
ElementUI.Dialog.props.closeOnPressEscape.default = false;
//导入自定义element-UI主题
// import "./style/theme/index.css"
// 导入公共样式
import './style/common.css';
import Aside from '@/components/aside.vue';
import { loadingMixin } from '@/utils/loading';
Vue.component('AsideTem', Aside);
// Vue.use(VueAxios, axios);
Vue.use(ElementUI);
Vue.mixin(loadingMixin);

Vue.config.productionTip = false;
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount('#app');
