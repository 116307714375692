import http from '@/utils/service';

/**
 * @description 获取推荐列表
 * @param params
 * @returns {*}
 */
export const getRecommendList = (params) => http.get('/footballSuggest/listAll', params);

/**
 * @description 获取推荐详情
 * @param id
 * @returns {*}
 */
export const getRecommendDetail = (id) => http.get(`/footballSuggest/selectById/${id}`);

/**
 * @description 获取比赛方案列表
 * @param params
 * @returns {*}
 */
export const getMatchPlan = (params) => http.get('/footballCombination/listCombination', params);

/**
 * @description 获取方案组合详情
 * @param id
 * @returns {*}
 */
export const getCombinationDetail = (id) => http.get(`/footballCombination/selectById/${id}`);

/**
 * @description 获取推荐类型
 * @returns {*}
 */
// export const getProType = () => http.get('footballCombination/selectNavigateList/0');
export const getProType = (type) => http.get(`footballCombination/selectNavigateList/0/${type}`);

/**
 * @description 获取第三方验证码
 * @returns {*}
 */
export const getThirdPartyCode = () => http.get('user/selectRequestCode');

/**
 * @description 发布推荐列表
 * @returns {*}
 * @param data
 */
export const publishRecommend = (data) => http.post('footballSuggest/update', data);

/**
 * @description 一键发布
 * @param ids
 * @returns {*}
 */
export const publishListRecommend = (ids) => http.post('footballSuggest/updatePublishByOne', ids);

/**
 * @description 修改推荐玩法
 * @param data
 * @returns {*}
 */
export const updateRecommendOdds = (data) => http.post('footballSuggest/updateOdds', data);

/**
 * @description 删除推荐列表
 * @param id
 * @returns {*}
 */
export const deleteRecommend = (id) => http.delete(`/footballSuggest/delete/${id}`);

/**
 * @description 删除比赛方案
 * @param id
 * @returns {*}
 */
export const deleteMatchPlan = (id) => http.delete(`/footballCombination/delete/${id}`);

/**
 * @description 更新推荐比赛组合
 * @param data
 * @returns {*}
 */
export const updateFootballCombination = (data) => http.post('/footballCombination/update', data);
