export default {
  namespaced: true,
  state() {
    return {
      chapterList: [], // 课程章列表
    };
  },
  mutations: {
    setChapterList(state, chapterList) {
      state.chapterList = chapterList;
    },
  },
};
