import http from '@/utils/service';

/**
 * @description 获取公告列表
 * @param params
 * @returns {*}
 */
export const getNoticeList = (params) => http.get('/notice/list', params);

/**
 * @description 新增公告
 * @param data
 * @returns {*}
 */
export const addNotice = (data) => http.post('/notice/add', data);

/**
 * @description 删除公告
 * @param data
 * @returns {*}
 */
export const updateNotice = (data) => http.post('/notice/update', data);

/**
 * @description 预览公告
 * @param id
 * @returns {*}
 */
export const previewNotice = (id) => http.get(`/notice/${id}`);

/**
 * @description 删除公告
 * @param id
 * @returns {*}
 */
export const deleteNotice = (id) => http.delete(`/notice/delete/${id}`);

/**
 * @description 批量删除公告
 * @param ids
 * @returns {*}
 */
export const deleteNotices = (ids) => http.delete(`/notice/deletes/${ids}`);

/**
 * 提示未读通知
 * @returns
 */
export const promptUnreadNoticeService = () => http.get('/userNotice/checkUnreadNotice');
