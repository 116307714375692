import Vue from 'vue';
import Vuex from 'vuex';
import { promptUnreadNoticeService } from '@/api/notice';
import course from './module/course';
import plan from './module/plan';

Vue.use(Vuex);

export default new Vuex.Store({
	strict: true,
	state: {
		username: sessionStorage.getItem('username'),
		rolename: sessionStorage.getItem('rolename'),
		// phone: sessionStorage.getItem('phone'),
		roleUrl: [],
		isLoading: false, // 是否是加载状态
		unreadNotice: 0, // 未读通知数
		apiMap: new Map([]), // 请求缓存
		menuList: {
			course: {}, // 当前实验
			chapter: {}, // 当前章节
			experiment: {}, // 上一级实验
			report: {}, // 上一级报告
		},
		selectList: JSON.parse(localStorage.getItem('combineList')),
	},
	getters: {
		isStudent(state) {
			return state.rolename === 'student';
		},
	},
	mutations: {
		getUsername(state) {
			state.username = sessionStorage.getItem('username');
		},
		getRolename(state) {
			state.rolename = sessionStorage.getItem('rolename');
		},
		updateUsername(state, newVal) {
			state.username = newVal;
		},
		// updatePhone(state, value) {
		//   state.phone = value
		// },
		updateSelect(state, value) {
			state.selectList = value;
		},
		setRoleUrl(state, newData) {
			state.roleUrl = newData;
		},
		setLoading(state, value) {
			state.isLoading = value;
		},
		setUnreadNotice(state, value) {
			state.unreadNotice = value;
		},
		setApiMap(state, value) {
			state.apiMap.set(
				value,
				setTimeout(() => {
					state.apiMap.delete(value);
				}, 500),
			);
		},
		// 获取课程管理面包屑
		getMenuList(state) {
			state.menuList = {
				course: JSON.parse(sessionStorage.getItem('course')),
				chapter: JSON.parse(sessionStorage.getItem('chapter')),
				experiment: JSON.parse(sessionStorage.getItem('experiment')),
				report: JSON.parse(sessionStorage.getItem('report')),
			};
			console.log(state.menuList, 'menuList');
		},
	},
	actions: {
		// 获取未阅读的系统通知数
		async getUnreadNotice(context) {
			const { data } = await promptUnreadNoticeService();
			context.commit('setUnreadNotice', data);
		},
	},
	modules: {
		course,
		plan,
	},
});
