// 当数据未完全加载出来时，显示加载中
export const loadingMixin = {
  data() {
    return {
      // loadingGlobal: true,
      titleLoading: false,
      dialogLoading: false,
      dataLoading: false,
      navLoading: false,
    };
  },
};
